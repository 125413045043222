import { AnswerWizardStep, AuthContext, AuthType, CustomerContext, GetLinkResponse, RedirectType, SaveLinkRequest, TemplateConfigKeyValue, TemplateElement, VermittlerDaten } from "./apimodel";
import { CSSProperties } from "@mui/styles";

export interface DefaultProps {
	
}

export interface UserState {
	name: string;
	email: string;
	company: string;
	access_token: string;
	refresh_token: string;
	status: string;
}

export interface AnswerElementDefaultState {
    error: string | null,
}

export interface AnswerElementDefaultProps extends DefaultProps {
    el: TemplateElement,
    handleChange: any,
    type: string | 'text',
    registerValidation: any,
    readonly: boolean,
    value: string | null
}

export interface AnswerState {    
    answer : SaveLinkRequest | null,
    link: GetLinkResponse | null,
    linkId: string | null,
    contact: CustomerContext | null,
    // Die Beantwortung des Links ist vollständig erfolgt
    answerComplete: boolean | false,
    // Der Link wurde bereits beantwortet
    wasAlreadyAnswered: boolean | false,
    // Laden-Call ans Backend läuft
    isFetching: boolean | false,
    // Speichern-Call ans Backend läuft
    isSaving: boolean | false,
    // Link darf nur angezeigt werden
    isReadonly: boolean | false,
    // Validierungsfehler
    errors: string[] | null,
    // Alle Validierungsfunktionen der Unterkomponenten zum Aufruf beim Speichern
    validationFunc: Map<string, any>,
    // Trigger für die initiale Berechnung der Dependencies
    initDependencies: boolean | null,
    // Trigger für die Neuberechnung von Dependencies
    recalcDependency: string | null,
    // Abhängigkeiten der Elemente untereinander als Map von elementid -> elementid[]
    dependencies: Map<string, string[]>,
    // Anzeigen der Elemente
    visible: Map<string, boolean>,
    // Elemente, die den Speichern-Button blockieren    
    buttonDisabledBy: string[],
    // Speichern-Button nicht anzeigen, wenn er blockiert ist?
    hideDisabledButton: boolean | false,
    // Der Link benötigt einen weiteren Faktor als Authentifizierung
    needsAuth: boolean | false,
    // Erneutes aktualisieren des Links erlaubt
    updateAllowed: boolean | false,
    // Art der Authentifizierung
    authType: AuthType | null,
    // Allgemeine Nachricht vom Backend
    message: string | null,
    // Fehler beim Holen / Speichern vom Backend
    errormessage: string | null,
    // Weitere Felder für die erweiterte Authentifizierung
    authContext:AuthContext,
    isProcessing: boolean | false,
    // Wartezeit bei zu vielen Fehlversuchen
    waitingTimeLeft: number | 0,
    // Template-Konfiguration
    config?: TemplateConfigKeyValue[],
    redirect?: RedirectType,
    wizardState: AnswerWizardState,
    mandatoryValidationError?: boolean,
    // Trigger für das Revalidieren der sichtbaren Seite.
    revalidate: number,
    // zu einem bestimmten Element scrollen
    scrollTo?: string,
    // zusätzliche Informationen, die auf dem Server abgelegt sind (Element highlighten, eine Nachricht)
    additionalAnswerStateContext?: AdditionalAnswerStateContext,
    // Ein Security-Token mit dem z.B. Zwischenspeichern möglich ist.
    additionalSecurityToken?:string,
    // Ein Token nach einer erfolgreichen TAN-Authentifizierung
    tanToken?:string,
    // Kontext des Teilen-Popups.
    shareDialogueContext: ShareDialogueContext,
    // Elemente, die gerade entfernt (ausgeblendet) werden
    elementsUnmounting:string[],
    // der letzte Wert des Elements vor dem Ausblenden, damit man ihn beim Wiedereinblenden wiederherstellen kann.
    lastValueBeforeUnmount: Map<string, any>,
    //Validierungsfehler die beim Speichern auftreten
    saveValidationMessage: string | null,
    // Wird der Postfach Link versteckt
    isHiddePostfachLink: boolean | false,
}

export interface ShareDialogueContext {
    visible: boolean | false,
    shareMessage: string | null,
    shareElementId: string | null
}

export interface AdditionalAnswerStateContext {
    // Nachricht, die am Teilen-Element eingegeben werden kann.
    shareMessage: string | undefined, 
    // Eine Element-ID, auf die sich das Teilen bezieht (z.B. Adressfeld o.ä.)
    shareElementid?: string
}

export interface InvalidElement {
    wizardStep: number,
    elementid: string | null
}

export interface AnswerWizardState {
    isWizard: boolean | false,  
    numberOfSteps: number | 0,
    currentStep: number | 0,
    isUnmounting: boolean | false,
    elementStepMapping?: Map<string, number>,
    wizardSteps?: AnswerWizardStep[],
    disabledFirstStep?: AnswerWizardStep
}

export interface AppState {
    // Informationen zum Vermittler, falls vorhanden
    vermittlerDaten?: VermittlerDaten,
	customizingId: string,
    customizing: ThemeCustomizing,
}

export enum HeaderLayout {
    FIXED_FULL_WIDTH,
    EMBEDDED_IN_CONTAINER
}

export interface ThemeCustomizing {
    headerLayout: HeaderLayout,
    buttonTextTransform?: "capitalize" | "full-size-kana" | "full-width" | "lowercase" | "none" | "uppercase",
    colors?: {
        background?: string | null,
        backgroundColor?: string | null,
		font?: string,        
        primary?: string,
		primaryContrast?: string,
		secondary?: string,
		secondaryContrast?: string,
        info?: string,
        infoLight?: string,
        infoContrast?: string,
        warning?: string,
        warningContrast?: string,
        headerBackgroundColor?: string | null,
        headerPrimaryColor?: string | null,
        footerBackgroundColor?: string | null,
        footerPrimaryColor?: string | null,
        trustElementBackgroundColor?: string | null,
        answerHeadingBackgroundColor?: string |null,
        decentBackground?: string | null,
        decentBackgroundOdd?: string | null,
        decentBackgroundEven?: string | null,
	},
    // Pro GUI-Element
    elements?: {
        // Header der GUI-Elemente
        heading? : {
            styleOverrides? : CSSProperties
        },
        // HTML-Block nach dem eigentlichen GUI-Element
        htmlAfter? : {
            styleOverrides? : CSSProperties
        },
        // HTML-Block vor dem eigentlichen GUI-Element
        informationtext? : {
            styleOverrides? : CSSProperties
        },
        // Allgemeine Style-Overrides der GUI-Elemente selbst
        styleOverrides? : CSSProperties,
        borderRadius?: string | null,
        padding?: string |null,
        backgroundEven?: string |null,
        backgroundOdd?: string |null,
        border?: string |null,
        margin?: string |null,
    }
    font?: {
        styleOverrides?: {}
        fontFamily?: string,
        headlineFontFamily?: string,
        fontWeightMedium?: number,
        fontWeightLight?: number,
        fontWeightBold?: number,
        fontWeightRegular?: number,
        fontSize?: number,
        color?:string
    },
    baseline?: {
        styleOverrides?: {}
    },
    MuiButton?:{
        styleOverrides?: {}
    },
    MuiInput?:{
        styleOverrides?: {}
    },
    MuiInputBase?:{
        styleOverrides?: {}
    },
    MuiFormControl?: {
        styleOverrides?: {}
    }
	logoSrc?: string | null,
    impressumSrc?: string | null,
    agbSrc?: string | null,
    datenschutzSrc?: string | null,
    copyright?: string | null,
    kontaktdatenAendern?: string,
    faq?: string,
    homepage?: string,
    homepageTitle?: string,
    title?: string,
    serviceTel?: string,
    serviceEmail?: string,
    trustElement?: string
}


