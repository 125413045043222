import { Box, FormControl, FormControlLabel, MenuItem, Select, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig, log } from '../../../../util/util';
import DOMPurify from 'dompurify';
import { SignupContext, SignupContextInterface } from '../../../signup/SignupProvider';
import { Typography, Link } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface AnswerElementSingleChoiceSignupProps extends AnswerElementDefaultProps {
    type: string | 'radio',
    addButtonDisabler: any,
    removeButtonDisabler: any,
    setNummerausgewaehlt: (value: boolean) => void
    setMobilnummerBestaetigt: (value: boolean) => void,
    setPending:  (value: boolean) => void,
}

interface AnswerElementSingleChoiceSignupState extends AnswerElementDefaultState {
    value: string | null,
    choices: SelectItem[],
    type: string,
    buttonHiddenWhileNotValidated: boolean | false
}

/**
 * 
 * Stellt eine Auswahlmöglichkeit bereit (entweder als Radiobutton (type="radio") oder als Dropdown (type="select")), aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */



class AnswerElementSingleChoiceSignup extends React.Component<AnswerElementSingleChoiceSignupProps, AnswerElementSingleChoiceSignupState> {




    constructor(props: any) {
        super(props);
        let value = this.props.value;
        let type: string = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.SELECT_TYPE]) || this.props.type;
        let choices: SelectItem[] = this.findChoicesInConfig();
        let buttonHiddenWhileNotValidated: boolean = Boolean(findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_HIDE_SAVE_BUTTON])) || false;

        this.state = { value: value, error: null, type: type, choices: choices, buttonHiddenWhileNotValidated: buttonHiddenWhileNotValidated };
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));

    }

    componentDidMount() {
        // Disablen des Speichern-Buttons einhängen
        this.props.addButtonDisabler(this.props.el.externalid, this.state.buttonHiddenWhileNotValidated);
        //this.getBestaetigteMobilnummerUndsetzeInputs();
    }




/*
    getBestaetigteMobilnummerUndsetzeInputs() {
        if (this.props.linkId !== null && this.props.authContext !== null && false === this.props.mobilnummerBestaetigt) {
            return LinkedappBackend.getContactConfirmation(this.props.linkId, this.props.authContext, ContactType.CELLPHONE).then((data) => {
                if (true === data.confirmed) {

                    if (!this.props.value) {
                        //Auswahl muss bei refresh übernommen werden.                 
                        const contactTypeKey = data.contactType as unknown as keyof typeof ContactType;
                        if (data?.recipient && data?.contactType && contactTypeKey === ContactType[ContactType.CELLPHONE]) {
                            //this.setState({ value: data.recipient.trim() });
                            //this.setState({ value: "NOENTRY" });
                          
                            this.props.setMobilnummer(data.recipient.trim());
                            
                            this.props.setPending(false);
                           
                            this.props.setNummerausgewaehlt(true);
                            this.props.setMobilnummerBestaetigt(true);
                          
                            this.props.removeButtonDisabler(this.props.el.externalid);
                            this.props.removeReplacementItem("{answerMobilnummer}");
                            this.props.addReplacementItem({ key: "{answerMobilnummer}", value: data.recipient.trim() });
                        
                            // maximum update depth exceeded : this.props.handleChange("NOENTRY", this.props.el.externalid);
                            //this.handleChangeMobilnummer("NOENTRY");
                            this.handleChangeMobilnummer(data.recipient.trim());
                            return true;
                        }
                    }

                }

            }).catch((error) => {
                // TODO: Fehlermeldung anzeigen
                console.log("Error: " + error);
            });
        }
    }
*/




    /**
     * In Material-UI ist keine Validierungsnachricht für Checkboxen vorgesehen.
     * Wir wollen aber trotzdem, dass bei einer nicht ausgefüllten Pflich-Checkbox eine Nachricht angezeigt wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && (this.state.value === null || this.state.value.length < 1)) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }





    render() {

        let errorMsg = this.getErrorMessage();
        let label = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL]);
        let cleanLabel: string = (label !== undefined && label !== null) ? DOMPurify.sanitize(label, { ADD_ATTR: ['target'] }) : '';


        const formatPhoneNumber = (number:string) => {
            // Analysiere die Telefonnummer und wandle sie in ein nationales Format um
            const phoneNumber = parsePhoneNumberFromString(number, 'DE'); // 'DE' für Deutschland
            if (!phoneNumber) return number; // Rückgabe der Originalnummer, falls die Analyse fehlschlägt

            // Formatieren im internationalen Stil mit Leerzeichen
            return phoneNumber.formatInternational();
        };


        const handleSetNummerausgewaehlt = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault(); // Verhindert das Standardverhalten des Links
            //this.setState({ ...this.state, pending: false });
            this.props.setPending(false);
            this.props?.setNummerausgewaehlt(false);
            this.props?.setMobilnummerBestaetigt(false);

        };

        if (this.state && this.props.el) {
            return (
                <SignupContext.Consumer>
                    {(signupContext: SignupContextInterface | undefined) => {
                        if (!signupContext) {
                            throw new Error('SignupContext.Consumer must be used within a SignupProvider');
                        }
                        const { mobilnummerBestaetigt, nummerausgewaehlt,mobilnummer } = signupContext;

                        if (this.state && this.props.el && nummerausgewaehlt) {
                            return (
                                <div className='control-wrapper'>
                                    <Box>
                                        Sie wollen die folgende Mobilfunknummer für Ihr Digitales Postfach nutzen.<br /><br />

                                        <Typography component="span" sx={{ fontWeight:'bold', paddingRight:'8em'}}>{formatPhoneNumber(mobilnummer)}</Typography>
                                        {!mobilnummerBestaetigt && <Link sx={{ display: 'flex-inline', textDecoration: 'none' }} onClick={handleSetNummerausgewaehlt}>
                                            ändern
                                        </Link> }

                                    </Box>
                                </div>
                            );
                        }

                        if (this.state && this.props.el && !nummerausgewaehlt) {
                            return (
                                <div className='control-wrapper'>
                                    <Box>
                                        {/* Falls Typ=Radio-Group */}
                                        {
                                            this.state.type === "radio" &&
                                            <>
                                                <div id="label" dangerouslySetInnerHTML={{ __html: cleanLabel }} /><br />
                                                <FormControl component="fieldset" className="radio" disabled={this.props.readonly === true || mobilnummerBestaetigt}>
                                                    <RadioGroup aria-label="select" value={this.state.value} >
                                                        {this.state.choices.map(element => {
                                                            let elLabel = (element.label !== undefined && element.label !== null) ? DOMPurify.sanitize(element.label, { ADD_ATTR: ['target'] }) : '';
                                                            return (<FormControlLabel key={element.value} value={element.value} control={<Radio />} label={<Box component="span" dangerouslySetInnerHTML={{ __html: elLabel }} />} onChange={this.handleChange} sx={{ paddingBottom: { xs: 'inherit', md: 'inherit' } }} />);
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>

                                            </>
                                        }
                                        {/* Falls Typ=Dropdown */}
                                        {
                                            this.state.type !== "radio" &&
                                            <FormControl variant="standard" className="select">
                                                <div id="demo-simple-select-standard-label" dangerouslySetInnerHTML={{ __html: cleanLabel }} /><br />
                                                <Select labelId="label" id="select" value={this.state.value} onChange={this.handleChange} className="select" disabled={this.props.readonly === true || mobilnummerBestaetigt} >
                                                    {this.state.choices.map(element => {
                                                        let elLabel = (element.label !== undefined && element.label !== null) ? DOMPurify.sanitize(element.label, { ADD_ATTR: ['target'] }) : '';
                                                        return (<MenuItem key={element.value} value={element.value}><Box component="span" dangerouslySetInnerHTML={{ __html: elLabel }} /></MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                        }
                                        {errorMsg}
                                    </Box>

                                    {!mobilnummerBestaetigt && <Box>
                                        {/* Hier könnte man etwas platizieren wenn die Nummer bestätigt wurde*/}

                                    </Box>}
                                </div>

                            )
                        }



                    }
                    }
                </SignupContext.Consumer>



            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key: string): string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig(): SelectItem[] {
        let items: SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
    validate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.props.el.mandatory === true && (this.state.value === null || this.state.value.length < 1)) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
                if (valiMsgMandatory) {
                    this.setState({ error: valiMsgMandatory }, () => {
                        log('AnswerElementSingleChoiceSignup: valid resolve false');
                        resolve(false);
                    });
                } else {
                    this.setState({ error: 'Bitte wählen Sie eine Option aus!' }, () => {
                        log('AnswerElementSingleChoiceSignup: valid resolve false');
                        resolve(false);
                    });
                }
            } else {
                this.setState({ error: null }, () => {
                    log('AnswerElementSingleChoiceSignup: valid resolve true');
                    resolve(true);
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc: any): any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {
        this.props.removeButtonDisabler(this.props.el.externalid);
        let value = event?.target?.value;
        this.setState({ value: value });
        this.props.handleChange(value, this.props.el.externalid);

    }
/*
    handleChangeMobilnummer(value: string) {
        this.props.removeButtonDisabler(this.props.el.externalid);
        this.setState({ value: value });
        this.props.handleChange(value, this.props.el.externalid);
    }
*/

}
export default AnswerElementSingleChoiceSignup