import { AnswerElement, ClientFieldValidationResult, ElementType, TemplateElementConfigKey } from '../../../../types/apimodel';
import AnswerElementTextInput from './AnswerElementTextInput';
import AnswerElementTextAreaInput from './AnswerElementTextAreaInput';
import AnswerElementDateInput from './AnswerElementDateInput';
import AnswerElementConfirmation from './AnswerElementConfirmation';
import { Box, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import AnswerElementPdfDocument from './AnswerElementPdfDocument';
import AnswerElementFileUpload from './AnswerElementFileUpload';
import { extractValueFromAnswerElement, extractValueFromArrayOfAnswerKeyValue, findValueInConfig, log } from '../../../../util/util';
import DOMPurify from 'dompurify';
import AnswerElementSingleChoice from './AnswerElementSingleChoice';
import AnswerElementSingleChoiceSignup from './AnswerElementSingleChoiceSignup';
import AnswerElementMultiChoice from './AnswerElementMultiChoice';
import AnswerElementAddress from './AnswerElementAddress';
import ShareIcon from '@mui/icons-material/Share';
import { AppState } from '../../../../types/types';
import AnswerElementEmailInputWithValidation from './AnswerElementEmailInputWithValidation';
import useAnswerCtx from '../useAnswerCtx';
import { ADD_DISABLE_BUTTON, ADD_VALIDATION_FUNC, IS_PROCESSING, REMOVE_DISABLE_BUTTON, SET_LAST_VALUE_BEFORE_UNMOUNT, SHARE_MENU_STATE } from '../../../../pages/answerLink/AnswerReducer';
import LinkedappBackend from '../../../../services/LinkedappBackend';
import AnswerElementPhoneInputWithValidation from './AnswerElementPhoneInputWithValidation';
import AnswerElementPhoneInputWithValidationSignup from './AnswerElementPhoneInputWithValidationSignup';
import AnswerElementBankData from './AnswerElementBankData';
import AnswerElementEmailOptin from './AnswerElementEmailOptin';
import AnswerElementPhoneOptin from './AnswerElementPhoneOptin';
import AnswerElementBankDataOnyIban from './AnswerElementBankDataOnyIban';
import React, {  useContext, useEffect, useState } from 'react';

import { IconButton } from '@mui/material';
import Tooltip  from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import AnswerElementConfirmationPostfachRegistrierung from './AnswerElementConfirmationPostfachRegistrierung';
import AnswerElementDiComUpdateConfirmation from './AnswerElementDiComUpdateConfirmation';
import { SignupContext } from '../../../signup/SignupProvider';
import { SuccessReplacementContext } from '../SuccessReplacementProvider';

interface AnswerElementProps {
    // bleibt, soviel ist kalr
    el: AnswerElement,
    // bisher nur benötigt für customization des Designs
    appState: AppState,
    jumpToWizardStep?: any | undefined,
    submitFormProgrammatically:() => void,
}


const AnswerElementGui: React.FC<AnswerElementProps> = (props) => {

    const { state, dispatch, handleChange } = useAnswerCtx();

    const [value, setValue] = useState(extractValueFromAnswerElement(props.el) as string | null);
    let inputfield;
    let elementType: string = String(props.el.element.type);
    let clean: string | null = (props.el.element.htmlAfter !== null && props.el.element.htmlAfter !== undefined && DOMPurify.sanitize(props.el.element.htmlAfter, { ADD_ATTR: ['target'] })) || null;
    let cleanInformationText: string | null = (props.el.element.informationtext !== undefined && props.el.element.informationtext !== null && DOMPurify.sanitize(props.el.element.informationtext, { ADD_ATTR: ['target'] })) || null;
    let viz: boolean = state.visible === undefined || state.visible.get(props.el.element.externalid) === undefined || state.visible.get(props.el.element.externalid) === true;
    let lastValueBeforeUnmount = state.lastValueBeforeUnmount.get(props.el.element.externalid);
    let cssOverrideString = findValueInConfig(props.el.element, TemplateElementConfigKey[TemplateElementConfigKey.CSS_OVERRIDES]);
    let templateElementConfigStyleOverrides = (cssOverrideString === "" || cssOverrideString === null || cssOverrideString === undefined) ? {} : JSON.parse(cssOverrideString.replace(new RegExp('\\"', 'g'), '"'));

    let tooltipText = findValueInConfig(props.el.element, TemplateElementConfigKey[TemplateElementConfigKey.TOOLTIP]);

    // Feld ist nur lesbar, unabhägig vom Beantwortungsstand der LinkedApp
    let readOnlyOverride: boolean = findValueInConfig(props.el.element, TemplateElementConfigKey[TemplateElementConfigKey.READONLY]) === 'true';
    // Feld ist versteckt, aber im DOM (visibility:hidden, Höhe 0)
    let hiddenOverrides = findValueInConfig(props.el.element, TemplateElementConfigKey[TemplateElementConfigKey.HIDDEN]) === 'true' ? {
        height: 0,
        width: 0,
        maxHeight: '0 !important',
        maxWidth: '0 !important',
        visibility: 'hidden !important',
        padding: 0,
    } : {};

    let mergedStyleOverrides = {
        ...props.appState?.customizing?.elements?.htmlAfter?.styleOverrides ?? {},
        ...templateElementConfigStyleOverrides ?? {},
        ...hiddenOverrides
    }

    useEffect(() => {
        // Wenn das Element gerade eingeblendet wird: den letzten Wert aus der Merkliste rausholen.
        if (viz && lastValueBeforeUnmount !== undefined) {
            setValue(extractValueFromArrayOfAnswerKeyValue(lastValueBeforeUnmount));
            // Wert wurde einmalig gesetzt, kann wieder raus aus der Merkliste.
            let newLastValueBeforeUnmount: Map<string, any> = state.lastValueBeforeUnmount;
            newLastValueBeforeUnmount.delete(props.el.element.externalid);
            dispatch({ type: SET_LAST_VALUE_BEFORE_UNMOUNT, lastValueBeforeUnmount: newLastValueBeforeUnmount })
            handleChange(value, props.el.element.externalid);
        }
    }, [viz, lastValueBeforeUnmount, value, dispatch, handleChange, props.el.element.externalid, state.lastValueBeforeUnmount]);

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    const registerValidation = function (validationFunction: any) {
        log('register validation for ' + props.el.element.externalid + ' and ' + validationFunction);
        if (state.mandatoryValidationError === true) {
            log('revalidate!');
            dispatch({ type: ADD_VALIDATION_FUNC, value: validationFunction, id: props.el.element.externalid, revalidate: true });
        } else {
            dispatch({ type: ADD_VALIDATION_FUNC, value: validationFunction, id: props.el.element.externalid, revalidate: false });
        }
    }

    /** Elemente, welches den Speichern-Button sperrt, anmelden */
    const addButtonDisabler = (elementid: string, hideButton: boolean) => {
        log('addButtonDisabler für ' + elementid);
        dispatch({ type: ADD_DISABLE_BUTTON, value: elementid, hideButton: hideButton });
    }

    /** Elemente, welches den Speichern-Button sperrt, entfernen */
    const removeButtonDisabler = (elementid: string) => {
        log('removeButtonDisabler für ' + elementid);
        dispatch({ type: REMOVE_DISABLE_BUTTON, value: elementid });
    }

    /** Klick auf das Teilen-Element: Popup hochbringen */
    const handleShareDialogueOpenClick = (elementid: string) => {
        dispatch({ type: SHARE_MENU_STATE, visible: true, shareElementId: elementid, shareMessage: "" });
    }

    const isVisible = function (): boolean {
        if (state.visible === undefined || state.visible.get(props.el.element.externalid) === undefined) {
            return true;
        }
        if (isUnMounting()) {
            // Elemente, die gerade ausgeblendet werden, noch 300 ms lang anzeigen. Steuerung per setTimeout in Answer.tsx
            return true;
        }
        log('visible for ' + props.el.element.externalid + ': ' + state.visible.get(props.el.element.externalid));
        return state.visible.get(props.el.element.externalid) === true;
    }

    const serverValidation = function (externalid: string, value: string): Promise<ClientFieldValidationResult> {
        dispatch({ type: IS_PROCESSING });
        return new Promise((resolve) => {
            if (state.linkId !== null) {
                LinkedappBackend.getFieldValidationResult(state.linkId, externalid, value, state.answer, state.authContext).then((result) => {
                    dispatch({ type: IS_PROCESSING, value: false });
                    resolve(result);
                });
            } else {
                dispatch({ type: IS_PROCESSING, value: false });
                resolve({ valid: true, error: undefined, payload: undefined });
            }
        });
    }

    /** Komponente gerade beim ausblenden? */
    const isUnMounting = function (): boolean {
        return state.elementsUnmounting !== undefined
            && state.elementsUnmounting !== null
            && state.elementsUnmounting.length > 0
            && state.elementsUnmounting.indexOf(props.el.element.externalid) > -1;
    }


    let htmlAfter = clean !== null && <Box dangerouslySetInnerHTML={{ __html: clean }} sx={{
        a: {
            '&:hover': {
                color: '#000000'
            },
            fontWeight: 400,
            textDecoration: 'underline'
        },
        ...props.appState?.customizing?.elements?.htmlAfter?.styleOverrides ?? {}
    }}></Box>;
    let headerVisible: boolean = (props.el.element.heading !== undefined && props.el.element.heading !== null && props.el.element.heading !== '');
    let renderInputfield: boolean = true;


    const signupContext = useContext(SignupContext);
    const successReplacementContext = useContext(SuccessReplacementContext);


    switch (elementType) {
        case ElementType[ElementType.DATA_INPUT_DATE]:
            inputfield = <AnswerElementDateInput el={props.el.element} value={value} handleChange={handleChange} type="date" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.DATA_INPUT_NUMBER]:
            inputfield = <AnswerElementTextInput el={props.el.element} value={value} handleChange={handleChange} type="number" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} serverValidation={serverValidation} />;
            break;
        case ElementType[ElementType.DATA_INPUT_TEXT]:
            inputfield = <AnswerElementTextInput el={props.el.element} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} serverValidation={serverValidation} />;
            break;
        case ElementType[ElementType.DATA_INPUT_TEXTAREA]:
            inputfield = <AnswerElementTextAreaInput el={props.el.element} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} serverValidation={serverValidation} />;
            break;
        case ElementType[ElementType.CONFIRMATION]:
            inputfield = <AnswerElementConfirmation el={props.el.element} value={value} handleChange={handleChange} type="checkbox" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.CONFIRMATION_POST_REG]:
            inputfield = <AnswerElementConfirmationPostfachRegistrierung el={props.el.element} value={value} handleChange={handleChange} type="checkbox" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} stateArrayOfAnswerElement={state.answer?.answer?.answerElement} jumpToWizardStep={props.jumpToWizardStep} customerContext={state.contact}/>;
            break;
        case ElementType[ElementType.DI_COM_KOM_UPDATE_CONFIRMATION]:
            inputfield = <AnswerElementDiComUpdateConfirmation el={props.el.element} value={value} handleChange={handleChange} type="checkbox" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} stateArrayOfAnswerElement={state.answer?.answer?.answerElement} jumpToWizardStep={props.jumpToWizardStep}/>;
            break;
        case ElementType[ElementType.SINGLE_CHOICE]:
            inputfield = <AnswerElementSingleChoice el={props.el.element} value={value} handleChange={handleChange} type="radio" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.SINGLE_CHOICE_SIGNUP]:
            inputfield = <AnswerElementSingleChoiceSignup  setPending={signupContext.setPending} setMobilnummerBestaetigt={signupContext.setMobilnummerBestaetigt} setNummerausgewaehlt={signupContext.setNummerausgewaehlt} addButtonDisabler={addButtonDisabler} removeButtonDisabler={removeButtonDisabler} el={props.el.element} value={value} handleChange={handleChange} type="radio" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.MULTI_CHOICE]:
            inputfield = <AnswerElementMultiChoice el={props.el.element} value={value} handleChange={handleChange} type="checkbox" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.DATA_INPUT_ADDRESS]:
            inputfield = <AnswerElementAddress el={props.el.element} value={value} handleChange={handleChange} type="radio" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.FILE_UPLOAD]:
            inputfield = <AnswerElementFileUpload el={props.el.element} value={value} handleChange={handleChange} type="file" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.INFORMATION]:
            renderInputfield = false;
            inputfield = <></>;
            break;
        case ElementType[ElementType.DOCUMENT_DOWNLOAD]:
            inputfield = <AnswerElementPdfDocument el={props.el.element} value={value} linkId={state.linkId} authContext={state.authContext} />;
            break;
        case ElementType[ElementType.EMAIL_INPUT_WITH_VALIDATION]:
            inputfield = <AnswerElementEmailInputWithValidation el={props.el.element} addButtonDisabler={addButtonDisabler} removeButtonDisabler={removeButtonDisabler} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} linkId={state.linkId} authContext={state.authContext} />;
            break;
        case ElementType[ElementType.PHONE_INPUT_WITH_VALIDATION]:
            inputfield = <AnswerElementPhoneInputWithValidation el={props.el.element} addButtonDisabler={addButtonDisabler} removeButtonDisabler={removeButtonDisabler} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} linkId={state.linkId} authContext={state.authContext} />;
            break;
        case ElementType[ElementType.PHONE_INPUT_WITH_VALIDATION_SIGNUP]:
            inputfield =  <AnswerElementPhoneInputWithValidationSignup setPending={signupContext.setPending} pending={signupContext.pending} setMobilnummer={signupContext.setMobilnummer}  setNummerausgewaehlt={signupContext.setNummerausgewaehlt} addReplacementItem={successReplacementContext.addReplacementItem}  replacementList={successReplacementContext.replacementList} submitFormProgrammatically={props.submitFormProgrammatically}  setMobilnummerBestaetigt={signupContext.setMobilnummerBestaetigt} el={props.el.element} addButtonDisabler={addButtonDisabler} removeButtonDisabler={removeButtonDisabler} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} linkId={state.linkId} authContext={state.authContext} />;
            break;
        case ElementType[ElementType.BANK_INPUT]:
            inputfield = <AnswerElementBankData el={props.el.element} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} serverValidation={serverValidation} />
            break;
        case ElementType[ElementType.BANK_INPUT_ONLY_IBAN]:
            inputfield = <AnswerElementBankDataOnyIban el={props.el.element} value={value} handleChange={handleChange} type="text" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} serverValidation={serverValidation} />
            break;
        case ElementType[ElementType.MAIL_DOUBLE_OPT_IN]:
            inputfield = <AnswerElementEmailOptin el={props.el.element} value={value} handleChange={handleChange} type="radio" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;
        case ElementType[ElementType.PHONE_DOUBLE_OPT_IN]:
            inputfield = <AnswerElementPhoneOptin el={props.el.element} value={value} handleChange={handleChange} type="radio" registerValidation={registerValidation} readonly={state.isReadonly || readOnlyOverride} />;
            break;

    }


   
    return (

        <>
            {isVisible() && <Box className={"answer-element " + (isUnMounting() ? "unmounting" : "")} id={props.el.element.externalid}
                sx={{
                    backgroundColor: 'inherit',
                    borderBottom: '1px solid',
                    borderBottomColor: '#ffffff',
                    borderLeft: '1px solid #ffffff42',
                    padding: '1rem',
                    background: props.appState?.customizing?.colors?.decentBackground || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    "&:nth-child(even)": {
                        background: props.appState?.customizing?.elements?.backgroundEven || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    },
                    "&:nth-child(odd)": {
                        background: props.appState?.customizing?.elements?.backgroundOdd || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    },
                    ...props.appState?.customizing?.elements?.styleOverrides ?? {},
                    ...mergedStyleOverrides ?? {},                    
                }}>

                <Grid container spacing={3}>
                    {headerVisible && <Grid item xs={11} className="answer-element-heading" sx={{
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        paddingBottom: '1rem',
                        ...props.appState?.customizing?.elements?.heading?.styleOverrides ?? {}
                    }}>
                        <div>{<span dangerouslySetInnerHTML={{ __html: props.el.element.heading }}></span>}</div>
                    </Grid>}


                    {tooltipText && <Grid item xs={1} className="answer-element-heading" sx={{
                        fontSize: '1.0rem',
                        fontWeight: 100,
                        paddingBottom: '1rem',
                        marginLeft:'-1em',
                        marginTop:'-0.5em',
                        ...props.appState?.customizing?.elements?.heading?.styleOverrides ?? {}
                    }}>


                        <div>
                            <Tooltip placement="bottom" color="secondary" title={
                                <React.Fragment >
                                    <div dangerouslySetInnerHTML={{ __html: tooltipText }} />
                                </React.Fragment>
                            } arrow>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>}





                    {state.additionalSecurityToken !== undefined && <Grid item xs={headerVisible ? 1 : 12} className="right">

                        <div className="share-menu">
                            <Link href="#" onClick={(event: any) => { event.preventDefault(); handleShareDialogueOpenClick(props.el.element.externalid); }}>
                                <ShareIcon fontSize="small" />
                            </Link>
                        </div>
                    </Grid>}

                    {
                        renderInputfield === true &&
                        <>
                            {(cleanInformationText !== undefined && cleanInformationText !== null) &&
                                <Grid item xs={12} className="informationtext-block">
                                    <Box dangerouslySetInnerHTML={{ __html: cleanInformationText }} className="informationtext-block-inner" sx={{ ...props.appState?.customizing?.elements?.htmlAfter?.styleOverrides ?? {} }}></Box>
                                </Grid>
                            }
                            <Grid item xs={12} className="control-block">
                                {inputfield}
                            </Grid>
                        </>
                    }
                    {
                        htmlAfter !== null && <Grid item xs={12} className="htmlafter-block">{htmlAfter}</Grid>
                    }
                </Grid>
            </Box>}
        </>
    );
}

export default AnswerElementGui