import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';

interface AnswerElementConfirmationProps extends AnswerElementDefaultProps {
    type: string | 'checkbox',
}

interface AnswerElementConfirmationState extends AnswerElementDefaultState {
    value: boolean | false
}

class AnswerElementConfirmation extends React.Component<AnswerElementConfirmationProps, AnswerElementConfirmationState> {
    
    constructor(props:any) {
        super(props);
        let value = this.props.value !== null ? (this.props.value  === 'true') : false;
        this.state = {value: value, error: null};
        this.handleChange = this.handleChange.bind(this);
        // Initialwert hochbubblen
        this.props.handleChange(String(value), this.props.el.externalid);
        this.registerValidation = this.registerValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.registerValidation(this.validate);
    }

    /**
     * In Material-UI ist keine Validierungsnachricht für Checkboxen vorgesehen.
     * Wir wollen aber trotzdem, dass bei einer nicht ausgefüllten Pflich-Checkbox eine Nachricht angezeigt wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && this.state.value !== true) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }

    render() {
        let errorMsg = this.getErrorMessage();
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.value} className="control-inner" color="primary" onChange={this.handleChange}  id={this.props.el.externalid} name={this.props.el.externalid} size="medium" disabled={this.props.readonly} />}                        
                        label={<Box component="span" sx={{wordBreak: {xs: 'break-word', md: 'initial'}}} dangerouslySetInnerHTML={{__html: this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL])}}></Box>}
                    />
                    {errorMsg}
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return this.state && findValueInConfig(this.props.el, key);
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {
            if (this.state.value !== true) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]) || 'Bitte bestätigen Sie die Checkbox!';
                if (this.props.el.mandatory === true) {
                    this.setState({error: valiMsgMandatory}, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({error: null}, () => {
                        resolve(true);
                    });
                }
            } else {
                this.setState({error: null}, () => {
                    resolve(true);
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {        
       this.setState({value: event.target.checked});
       this.props.handleChange(String(event.target.checked), this.props.el.externalid);
    }

}
export default AnswerElementConfirmation