import { useEffect, ReactNode, useRef, useContext } from 'react';
import AnswerElementGui from '../../components/pages/answerLink/elements/AnswerElementGui';
import { AnswerElement, CustomerContext, SaveLinkRequest, GetLinkResponse, AuthType, AuthContext, ExtraElement, ExtraElementType, TemplateConfigKey, RedirectType, AuthKeyValue, AuthKey, AuthResultKey } from '../../types/apimodel';
import './answer.css';
import CustomerContactBlock from '../../components/pages/answerLink/CustomerContactBlock';
import { deepCopy, generateCorrelationId, log, findValueInTemplateConfig } from '../../util/util';
import LinkedappBackend from '../../services/LinkedappBackend';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import { AdditionalAnswerStateContext, AnswerWizardState, AppState, DefaultProps, InvalidElement } from '../../types/types';
import Success from '../../components/pages/answerLink/Success';
import { AUTH_WAIT, ERROR_MSG, HANDLE_DEPENDENCIES, INIT_DEPENDENCIES_AND_VISIBILITIES, IS_FETCHING, IS_SAVING, REMOVE_UNMOUNTING_ELEMENTS, RESET_SCROLL_TO, SAVED, SET_ADDITIONAL_SECURITY_TOKEN, SET_AUTHCONTEXT, SET_DATA, SET_DEPENDENCIES_AND_VISIBILITIES, WIZARD_STEP_VALIDATION_ERROR, VALIDATION_ON_SAVE_FAILED, PRE_SAVE_VALIDATION_ERROR } from './AnswerReducer';
import { loadStorageData, loadSessionData, saveSessionData, clearSessionValue, KEY_AUTH_CONTEXT, KEY_CUSTOMER_HISTORY } from '../../util/store';
import ShareDialog from '../../components/pages/answerLink/ShareDialog';
import { extractAdditionalAnswerStateContext, getWizardState, validateMandatoryElements } from '../../components/pages/answerLink/wizard/WizardHelper';
import useAnswerCtx from '../../components/pages/answerLink/useAnswerCtx';
import { initVisibilityAndDependencies, recalcValidationFuncs, recalcVisibility, updateLastValueBeforeUnmount, validateVisibleElements } from '../../components/pages/answerLink/DependencyVisibilityHelper';
import Wizard from '../../components/pages/answerLink/wizard/Wizard';
import HighlightFrame from '../../components/pages/answerLink/wizard/HighlightFrame';
import AuthRequired from '../../components/pages/answerLink/auth/AuthRequired';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

import ValidationMessage from '../../components/pages/answerLink/elements/ValidationMessage';
import React from 'react';
import { SuccessReplacementContext } from '../../components/pages/answerLink/SuccessReplacementProvider';
import {  CID_RHEINLAND, CID_RHION } from '../../types/constants';

interface AnswerProps extends DefaultProps {
    // bisher nur benötigt für customization des Designs
    appState: AppState,
    answer: SaveLinkRequest | null,
    link: GetLinkResponse | null,
    contact: CustomerContext | null,
    page?: any,
    // hochbubblen der Vermittlerdaten
    setVermittlerDaten: any
}


function Answer(props: AnswerProps) {
    const { state, dispatch } = useAnswerCtx();
    const history = useHistory();
    const location = useLocation();

    //const submitButtonRef =   React.createRef();
 

    const formRef = useRef<HTMLFormElement | null>(null);


    const submitFormProgrammatically = () => {
        if (formRef?.current){
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      };


    /** Abfrage, ob der Speichern-Button disabled werden soll (wenn Elemente ihn aktiv sperren) */
    const isButtonDisabled = (): boolean => {
        return state.buttonDisabledBy !== undefined && state.buttonDisabledBy.length !== 0;
    }

    /** Neu validieren. */
    useEffect(() => {
        log('revalidating');
        validateVisibleElements(state.validationFunc);
        // interessant sind nur die Änderungen an state.revalidate, aber der Linter heult, wenn hier nicht alle Dependencies gepflegt sind.
        // eslint-disable-next-line
    }, [state.revalidate]);

    /** Scroll-Animation starten. */
    useEffect(() => {
        if (state.scrollTo !== undefined && state.scrollTo !== null) {
            log('scrollTo ' + state.scrollTo);
            setTimeout(() => {
                let scrollToElement: any = document.getElementById("" + state.scrollTo);
                if (scrollToElement !== undefined && scrollToElement !== null) {
                    scrollToElement.scrollIntoView({ block: "start", behavior: "smooth" });
                    dispatch({ type: RESET_SCROLL_TO });
                }
            }, 300);
        }
    }, [state.scrollTo, dispatch]);

    /**
     * Bekommt den AuthContext aus AuthRequired hochgereicht, damit der
     * zweite Call gegen den Server inklusive Authentifizierungsinformationen
     * gemacht werden kann.
     * 
     * @param authContext 
     */
    const updateAuthContext = function (authContext: AuthContext): any {
        // Die TAN selbst soll nicht im AuthContext in der Session verbleiben.
        let newAuthCtx: AuthContext = {
            value: {
                value:
                    authContext.value.value.filter((value: AuthKeyValue) => {
                        return String(value.key) !== AuthKey[AuthKey.TAN];
                    })
            }
        }
        saveSessionData(KEY_AUTH_CONTEXT, newAuthCtx);
        dispatch({ type: SET_AUTHCONTEXT, value: authContext, needsAuth: false, isFetching: true });
    }


    const getCidFromUrl = function() :string {
        let windowUrl = window.location.search;
        let params = new URLSearchParams(windowUrl);
        let cid:string = params.get('cid') || '';
        return cid;
    }

    /** Link vom Server holen. */
    const loadLink = function () {
        if (state.isFetching) {
            let windowUrl = window.location.search;
            let params = new URLSearchParams(windowUrl);
            let linkId: string = params.get('linkid') || '';
            let correlationId = generateCorrelationId();
            log('loadLink state: ' + JSON.stringify(state))
            let authContext = state.authContext;
            if (authContext === undefined || authContext === null || authContext.value?.value?.length === 0) {
                let auth = loadSessionData(KEY_AUTH_CONTEXT);
                if (auth !== undefined && auth !== null) {
                    authContext = auth;
                    dispatch({ type: SET_AUTHCONTEXT, value: authContext });
                }
            }

            LinkedappBackend.getLink(linkId, authContext).then((data) => {
                if (data !== null) {
                    // Kopie von dem ganzen Kram machen und als Answer-Objekt 
                    // in den State packen, so dass man das direkt gegen den Server feuern kann.

                    let saveLinkAnswer: SaveLinkRequest = {
                        id: linkId,
                        answer: deepCopy(data.answer),
                        correlationId: correlationId,
                        authContext: authContext
                    };
                    log('data: ' + JSON.stringify(data));
                    let additionalAnswerStateContext: AdditionalAnswerStateContext | undefined = extractAdditionalAnswerStateContext(data)
                    let wizardState: AnswerWizardState = getWizardState(data, additionalAnswerStateContext, props.page);
                    // aktuelle Seite setzen, wenn Wizard gefunden
                    if (wizardState.isWizard === true) {
                        setPathToPage(wizardState.currentStep);
                    }
                    let tanToken = undefined;
                    if (data.authResult !== null && String(data.authResult.key) === AuthResultKey[AuthResultKey.TAN_TOKEN] && data.authResult.success === true) {
                        tanToken = data.authResult.value;
                        // Wenn die TAN gegen ein Token getauscht ist, diese aus dem Kontext entfernen.
                        authContext.value.value = authContext.value.value.filter((kv) => { return String(kv.key) !== AuthKey[AuthKey.TAN_TOKEN] && String(kv.key) !== AuthKey[AuthKey.TAN] });
                        let key: any = AuthKey[AuthKey.TAN_TOKEN];
                        authContext.value.value.push({ key: key, value: tanToken });
                        saveSessionData(KEY_AUTH_CONTEXT, authContext);
                    }
                    if (data.authResult !== null && data.authResult.success === false) {
                        clearSessionValue(KEY_AUTH_CONTEXT);
                    }
                    if (data.historyID !== undefined && data.historyID !== null) {
                        saveSessionData(KEY_CUSTOMER_HISTORY, data.historyID);
                    }
                    let isHidePostfachLink = findValueInTemplateConfig(data.templateConfig?.value, TemplateConfigKey[TemplateConfigKey.HIDE_POSTFACH_LINK]);
                    if (!isHidePostfachLink) {
                        //FIXME Aus irgendeinem Grund ist props nach refresh null
                        if (props === undefined || props === null) {
                            //M.R. undefined wurde im debugger beobachtet, aber lokal konnte ich es nicht nachstellen
                            let cid = getCidFromUrl();
                            if (cid === 'cl') {
                                isHidePostfachLink = "true";
                            }
                        } else {
                            let cid = props.appState?.customizingId;
                            if (cid === 'void'){
                                //M.R. Nach refresh wurde es "void", obwohl der Parameter ein anderer war
                                cid =  getCidFromUrl();
                            } 
                            if (cid === 'cl') {
                                isHidePostfachLink = "true";
                            }
                        }
                    }
                    dispatch({ type: SET_DATA, answer: saveLinkAnswer, authContext: authContext, data: data, wizardState: wizardState, additionalAnswerStateContext: additionalAnswerStateContext, isHiddePostfachLink: isHidePostfachLink === "true" });
                } else {
                    dispatch({ type: IS_FETCHING, value: false });
                }
            }).catch((error) => {
                log('Fehler beim Holen des Links: ' + JSON.stringify(error));
                let errorMsg = error.message ? error.message : 'Leider ist ein Fehler beim Speichern aufgetreten. Wir haben den Fehler zu folgender ID protokolliert: ' + correlationId;
                dispatch({ type: ERROR_MSG, value: errorMsg });
                dispatch({ type: IS_FETCHING, value: false });
            });
        }
    }

    /** Einen Link auf dem Server zwischenspeichern. Besondere Berechtigung benötigt. */
    const saveLinkTemporary = function (shareElementId: string | null, shareMessage: string | null) {
        LinkedappBackend.saveLinkTemporary(shareElementId, shareMessage, state.answer, state.authContext, state.additionalSecurityToken).then(
            (outcome) =>
                dispatch({ type: SAVED, complete: outcome.complete, errorMsg: outcome.errorMsg })
        );
    }

    /** Setzt den übergebenen Wizard-Step als Path-Parameter in den Pfad */
    const setPathToPage = function (nextStep: number) {
        let path = location.pathname;
        if (path.indexOf('/page/') > -1) {
            path = path.replace(/\/page\/.*\//, "/page/" + nextStep + "/");
        } else {
            if (!path.endsWith("/")) {
                path = path + "/";
            }
            path = path + "page/" + nextStep + "/";
        }
        history.replace(path + location.search);
    }

    // Initialladen, Ersatz für componentDidMount()
    useEffect(() => {
        dispatch({ type: IS_FETCHING });
    }, [dispatch]);

    /** Laden, sobald isFetching gesetzt ist. */
    useEffect(() => {
        if (state.isFetching === true) {
            loadLink();
        } else {
            if (state.link?.vermittlerDaten !== undefined) {
                // AnswerState nach oben an die App geben, damit der Vermittler im Header dargestellt werden kann
                props.setVermittlerDaten(state.link?.vermittlerDaten);
            }
        }
        // interessant sind nur die Änderungen an state.isFetching, aber der Linter heult, wenn hier nicht alle Dependencies gepflegt sind.
        // eslint-disable-next-line
    }, [state.isFetching, state.link?.vermittlerDaten]);

    /** Wartesperre prüfen. */
    useEffect(() => {
        if (state.link !== null && state.link.waitUntil !== null && state.waitingTimeLeft > 0) {
            setTimeout(() => {
                dispatch({ type: AUTH_WAIT })
            }, 1000);
        }
    }, [state.waitingTimeLeft, state.link, dispatch]);

    /** Initialisieren nach Neuladen des Link vom Server:
     *  Initiales Berechnen des Sichtbarkeitsbaums und der Validierungsfunktionen.
     */
    useEffect(() => {
        if (state.link !== null && state.link !== undefined) {
            // Falls eine Wartesperre angezeigt werden soll
            if (state.link !== null && state.link.waitUntil !== null) {
                if (new Date(state.link.waitUntil).getTime() - new Date().getTime() > 0) {
                    log('Setze one-shot timer...');
                    // neu laden
                    setTimeout(() => {
                        window.location.reload();
                    }, state.waitingTimeLeft + 500);
                }
            }
            // Wenn ein Token da ist, setzen.
            let posSecurityToken: string = loadStorageData("POS_SECURITY_TOKEN");
            if (posSecurityToken !== undefined && posSecurityToken !== null) {
                dispatch({ type: SET_ADDITIONAL_SECURITY_TOKEN, additionalSecurityToken: posSecurityToken });
            }
            // Eigentliche Initialisierung anstoßen, sie useEffect auf state.initDependencies
            // Auseinandergezogen, weil wegen der Dependency-Arrays von useEffect das hier pausenlos ziehen würde.
            dispatch({ type: INIT_DEPENDENCIES_AND_VISIBILITIES });
        }
    }, [state.link, state.waitingTimeLeft, dispatch]);


    /** Wenn der Link vom Server geladen ist, initial die Sichtbarkeiten und aktiven Validierungsfunktionen in den State legen. */
    useEffect(() => {
        // Genau einmal der Fall nach dem Laden
        if (state.initDependencies === true && state.answer !== null) {
            // Dependencies eintragen in der Form: elementid hat dependencies zu elementid[]
            const [dependencies, visibilities] = initVisibilityAndDependencies(state.link);

            // Kopie des letzten Zustands anfertigen (ist hier mit jedes element ist sichtbar initialisiert), um die differenz für eine animation zu bestimmen.
            let lastVisibleState: Map<string, boolean> = new Map<string, boolean>(visibilities);

            // Sichtbarkeit, Validierungen und Co. neu berechnen und in den State legen. Antworten von unsichtbaren Elementen entfernen.
            let [visible, answer] = recalcVisibility(visibilities, deepCopy(state.answer), dependencies);

            // Auszublendende Elemente bestimmen und deren Werte merken
            let elementsUnmounting: string[] = Array.from(visible.keys()).filter((elementid: string) => ((lastVisibleState.get(elementid) === true && visible.get(elementid) === false) || ((lastVisibleState.get(elementid) === undefined) && visible.get(elementid) === false)));
            // Für auszublendende Elemente den letzten Wert merken, falls sie wieder eingeblendet werden.
            let lastValueBeforeUnmount = updateLastValueBeforeUnmount(state.lastValueBeforeUnmount, elementsUnmounting, state.answer);
            // Validierungsfunktionen aushängen, die gerade nicht aktiv sind.
            let validationFuncs: Map<string, any> = recalcValidationFuncs(state.validationFunc, visible);
            // Alles in den State packen.
            dispatch({ type: SET_DEPENDENCIES_AND_VISIBILITIES, validationFunc: validationFuncs, dependencies: dependencies, visible: visible, answer: answer, initDependencies: false, lastValueBeforeUnmount: lastValueBeforeUnmount });
        }
    }, [state.initDependencies, state.link, state.answer, state.validationFunc, state.lastValueBeforeUnmount, dispatch]);


    /** Neuberechnen des Sichtbarkeitsbaums und der Validierungsfunktionen.
     *  Wird immer aufgerufen, wenn sich im Baum irgendetwas ändert und ggf. Elemente ein- oder ausgeblendet werden müssen. */
    useEffect(() => {
        // Achtung: state.recalcDependency === null ist der Initial-Fall.
        if (state.recalcDependency !== undefined && state.answer !== null && state.initDependencies !== true) {
            log('recalc: ' + state.recalcDependency);
            // Kopie des letzten Zustands anfertigen (ist hier mit jedes element ist sichtbar initialisiert), um die differenz für eine animation zu bestimmen.
            let lastVisibleState: Map<string, boolean> = new Map<string, boolean>(state.visible);
            // Sichtbarkeit, Validierungen und Co. neu berechnen und in den State legen. Antworten von unsichtbaren Elementen entfernen.
            let [visible, answer] = recalcVisibility(state.visible, deepCopy(state.answer), state.dependencies, state.recalcDependency);
            // Auszublendende Elemente bestimmen für Animation
            let elementsUnmounting: string[] = Array.from(visible.keys()).filter((elementid: string) => ((lastVisibleState.get(elementid) === true && visible.get(elementid) === false) || ((lastVisibleState.get(elementid) === undefined) && visible.get(elementid) === false)));
            log('elementsUnmounting: ' + JSON.stringify(elementsUnmounting));
            // Für auszublendende Elemente den letzten Wert merken, falls sie wieder eingeblendet werden.
            let lastValueBeforeUnmount = updateLastValueBeforeUnmount(state.lastValueBeforeUnmount, elementsUnmounting, state.answer);
            // wenn elemente ausgeblendet werden, dann müssen die werte der elemente darunter auch zurückgesetzt werden.
            log('visibility vor HANDLE_DEPENDENCIES von ' + state.recalcDependency + ': ' + JSON.stringify(Array.from(visible.keys())) + ";" + JSON.stringify(Array.from(visible.values())));
            let validationFuncs: Map<string, any> = recalcValidationFuncs(state.validationFunc, visible);
            log('recalcDependency. validationFuncs:' + (validationFuncs && JSON.stringify(Array.from(validationFuncs.keys()))));
            dispatch({ type: HANDLE_DEPENDENCIES, visible: visible, recalcDependency: undefined, validationFunc: validationFuncs, answer: answer, elementsUnmounting: elementsUnmounting, lastValueBeforeUnmount: lastValueBeforeUnmount });
            // Nach 300 ms die elementsUnmounting wieder entfernen!
            setTimeout(() => {
                dispatch({ type: REMOVE_UNMOUNTING_ELEMENTS, elementsUnmounting: elementsUnmounting });
            }, 300);

        }
        // interessant sind nur die Änderungen an state.recalcDependency, aber der Linter heult, wenn hier nicht alle Dependencies gepflegt sind.
        // eslint-disable-next-line
    }, [state.recalcDependency, state.visible, state.answer, state.dependencies, state.validationFunc, state.initDependencies]);


    const onSubmit = function (event?: any) {
        state.saveValidationMessage = null;
        event?.preventDefault();
        log('Validiere Mandatory...');
        // Erstmal alle Pflichtfelder validieren!
        let firstInvalidWizardStep: InvalidElement = validateMandatoryElements(state.answer, state.wizardState.elementStepMapping, state.visible);
        // Wizard
        log('firstInvalidWizardStep: ' + JSON.stringify(firstInvalidWizardStep) + "; doSubmit: " + state.mandatoryValidationError);
        // Wenn Wizard und irgendein Pflichtfeld fehlt: dahinspringen
        // Solange ein pflichtfeld fehlt, kann hier nicht IS_SAVING aufgerufen werden.
        if (state.wizardState.isWizard === true) {
            if (firstInvalidWizardStep.wizardStep > -1) {
                // Auf Seite 1 springen und submitten
                validateVisibleElements(state.validationFunc).then((validationResult) => {
                    if (validationResult.valid === true) {
                        if (state.answer !== null) {
                            dispatch({ type: IS_SAVING });
                            LinkedappBackend.saveLink(state.answer).then(
                                (outcome: any) => dispatch({ type: SAVED, complete: outcome.complete, errorMsg: outcome.errorMsg })
                            ).catch(
                                (outcome: any) => {
                                    dispatch({ type: ERROR_MSG, saveValidationMessage: outcome.errorMsg })
                                }
                            );

                        }
                        log(JSON.stringify(state.answer));
                    } else {
                        log('Validierungsergebnis: nicht erfolgreich.');
                    }
                });
                setPathToPage(firstInvalidWizardStep.wizardStep);
                dispatch({ type: WIZARD_STEP_VALIDATION_ERROR, value: firstInvalidWizardStep.wizardStep, scrollTo: firstInvalidWizardStep.elementid });
            } else {
                if (state.answer !== null) {
                    dispatch({ type: IS_SAVING });
                    LinkedappBackend.saveLink(state.answer).then(
                        (outcome: any) => dispatch({ type: SAVED, complete: outcome.complete, errorMsg: outcome.errorMsg })
                    ).catch(
                        (outcome: any) => {
                            dispatch({ type: VALIDATION_ON_SAVE_FAILED, saveValidationMessage: outcome.errorMsg })
                        }
                    );

                }
                log(JSON.stringify(state.answer));
            }
        } else {
            validateVisibleElements(state.validationFunc).then((validationResult) => {
                if (validationResult.valid === true) {
                    if (state.answer !== null) {
                        dispatch({ type: IS_SAVING });
                        LinkedappBackend.saveLink(state.answer).then(
                            (outcome: any) => dispatch({ type: SAVED, complete: outcome.complete, errorMsg: outcome.errorMsg })
                        ).catch(
                            (outcome: any) => {
                                dispatch({ type: VALIDATION_ON_SAVE_FAILED, saveValidationMessage: outcome.errorMsg })
                            }
                        );
                    }
                    log(JSON.stringify(state.answer));
                } else {
                    log('Validierungsergebnis: nicht erfolgreich.');
                    dispatch({ type: PRE_SAVE_VALIDATION_ERROR, scrollTo: findFirstInvalidElement(validationResult.invalidElementIds) });
                }
            });
        }
    }

    const findFirstInvalidElement = function (invalidElementIds: string[]): string {
        if (state?.answer?.answer.answerElement.value) {
            for (var i = 0; i < state?.answer?.answer.answerElement.value.length; i++) {
                if (invalidElementIds.indexOf(state?.answer?.answer.answerElement.value[i].element.externalid) > -1) {
                    return state?.answer?.answer.answerElement.value[i].element.externalid;
                }
            }
        }
        return invalidElementIds[0];
    }

    /**
     * Rückmeldung vom Server.
     * @returns 
     */
    const getMessage = function () {
        if (state && state.message) {
            return (
                <div className="message">
                    <p><b>{state.message}</b></p>
                </div>
            );
        }
    }

    const getExpiredMessage = function () {
        if (state && state.link !== null && state.link.expired === true) {
            let message: string = state.message !== null ? state.message : "Dieser Link ist leider abgelaufen."
            return (
                <div className="message">
                    <p><b>{message}</b></p>
                </div>
            );
        }
    }

    const getLandingpageLink = function(){
        //TODO konfigurierbar machen
        //https://digitalespostfach.rhl.ag/landing/registrieren?cid=rl
        //https://digitalespostfach.rhion.ag/landing/registrieren?cid=rd
        let landingpageRhion = process.env.REACT_APP_RHION_LANDINGPAGE;
        let landingpageRheinland = process.env.REACT_APP_RHEINLAND_LANDINGPAGE;
        let cid = getCidFromUrl();
        if (cid === CID_RHION) {
            return (
                <a href={landingpageRhion} target="_blank" rel="noreferrer">Link</a>
               
            );
        } else if (cid === CID_RHEINLAND) {
            return (
                <a href={landingpageRheinland} target="_blank" rel="noreferrer">Link</a>
               
            );
        }
        
    }
    /**
     * Serverfehler rendern.
     * @returns 
     */
    const getErrorMessage = function () {
        let cid = getCidFromUrl();
        if (state && state.errormessage) {
            return (
                <div className="errormessage">
                    <p><b>{state.errormessage}</b></p>
                    { (cid !== CID_RHION && cid !== CID_RHEINLAND) &&  <div>
                        <p>Wenn der Fehler weiterhin besteht, dann wenden Sie sich bitte an unseren Kundenservice:</p>
                        Telefon: <a href={"tel:" + props.appState?.customizing?.serviceTel}>{props.appState?.customizing?.serviceTel}</a>
                        </div>
                    }
                    { (cid === CID_RHION || cid === CID_RHEINLAND) &&  <div>
                    <p>Es tut uns leid, aber es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es morgen noch einmal. 
                        <br/><br/>Alternativ können Sie, sofern eine Versicherungsscheinnummer vorliegt, diesen {getLandingpageLink()} nutzen, um fortzufahren.
                        <br/> Vielen Dank für Ihr Verständnis!</p>
                        </div>
                    }
                </div>
            );
        }
    }



    /**
     * Speichern-Button, je nach Zustand, rendern
     * @returns 
     */
    const getSaveButton = function () {
        let buttonText: String = "Antwort absenden!";
        if (state.config !== undefined && state.config !== null) {
            let buttonConf: string = findValueInTemplateConfig(state.config, TemplateConfigKey[TemplateConfigKey.SUBMIT_BUTTON_TEXT])
            if (buttonConf && buttonConf !== null) {
                buttonText = buttonConf;
            }
        }
        if ((state.link !== null && state?.link?.needsAnswer === false) && (state.redirect === null || (state.redirect !== null && RedirectType[RedirectType.AFTER_ANSWER] !== String(state.redirect)))) {
            return <></>
        }
        if (state.answer !== null && (true === state.updateAllowed || false === state.wasAlreadyAnswered)) {
            return (<Button type="submit" title="Absenden" disabled={state.isProcessing === true || isButtonDisabled() === true} sx={{ display: (state.hideDisabledButton && isButtonDisabled()) ? 'none' : 'inline-block' }}>{buttonText}</Button>);
        } else {
            return (
                <Grid container spacing={3} className="padding-3">
                    <Grid item xs={12} className="center">
                        Diese LinkedApp wurde bereits beantwortet und kann daher nicht mehr gespeichert werden.
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Block, in dem der Kunde über das Medium informiert wird, an das der
     * Link geschickt wurde (zur Überprüfung)
     * @returns 
     */
    const getContactBlock = function () {
        let text = null;
        if (state?.link?.extraElements?.value?.values !== null) {
            let contact: ExtraElement | undefined = state?.link?.extraElements?.value.find(e => ExtraElementType[ExtraElementType.CUSTOMER_CONTACT] === String(e.type));
            if (contact !== undefined && contact !== null) {
                text = contact.html;
            }
        }
        return <CustomerContactBlock el={state.contact} text={text} />
    }

    /**
     * Authorisierung: wird gerendert, wenn eine erweiterte Authentifizierung benötigt wird.
     * @returns 
     */
    const getAuthElement = function () {
        if (state?.link?.extraElements?.value?.values !== null) {
            let auth: ExtraElement | undefined = state?.link?.extraElements?.value.find(e => ExtraElementType[ExtraElementType.AUTH] === String(e.type));
            let html = (auth !== undefined && auth !== null) ? auth.html : undefined;
            return <AuthRequired link={state.link} linkId={state.linkId} appState={props.appState} updateAuthContext={updateAuthContext} html={html} />;
        }
    }

    const successReplacementContext = useContext(SuccessReplacementContext);

    /**
     * Erfolgsseite, wird gerendert, wenn der Anwender den Prozess durchlaufen hat.
     * @returns 
     */
    const getSuccessElement = (container: boolean): ReactNode => {
        let html = undefined;

        if (state?.link?.extraElements?.value?.values !== null) {
            let success: ExtraElement | undefined = state?.link?.extraElements?.value.find(e => ExtraElementType[ExtraElementType.SUCCESS] === String(e.type));
            if (success !== undefined && success !== null && success.html !== null) {
                html = success.html;
            }
        }
        return <Success html={html} appState={props.appState} container={container} replacementList={successReplacementContext.replacementList}></Success>
        //return <Success html={html} appState={props.appState} container={container}></Success>
    }

 
      
    /**
     * Springt in das Kundenpostfach.
     * @param event 
     */
    const toCustomerHistory = function (event: any) {
        if (state.link?.historyID !== undefined) {
            let queryString = window.location.search || '';
            let urlParams = new URLSearchParams(queryString);

            urlParams.set('linkid', state.link?.historyID);
            let url = '/link/open?' + urlParams.toString();
            //console.log("Öffne Kundenakte: " + url);

            dispatch({ type: IS_FETCHING, linkId: state.link?.historyID });
            window.location.href = url;

        }
    }

    if (state && state.link !== null && state.link.expired === true) {
        let expiredmessage = getExpiredMessage();
        return (
            <div className="padding-3">
                {expiredmessage}
            </div>);

    }

    // Rendert einen Fehlerblock.
    let errormessage = getErrorMessage();
    // z.B. beim Laden des Links passiert ein 500er. Dann direkt abbrechen
    if (state.errormessage) {
        return (
            <div className="padding-3">
                {errormessage}
            </div>
        );
    }

    if (state.link?.waitUntil !== null && state.waitingTimeLeft > 0 && !state.isFetching && !state.isSaving) {
        return (
            <div className="padding-3">
                <h3>Sie haben die Höchstanzahl von Fehlversuchen überschritten.</h3>
                Aus Sicherheitsgründen bitten wir Sie, sich noch {Math.round(state.waitingTimeLeft / 1000)} Sekunden zu gedulden. Diese Seite aktualisiert sich dann automatisch.
            </div>
        );
    }

    // Link benötigt erweiterte Auth
    if (true === state.needsAuth && AuthType[AuthType.TOKEN] !== String(state.authType)) {
        let auth = getAuthElement();
        return (
            <div>{auth}</div>
        );
    }

    // Link wurde nicht gefunden
    if ((state.answer === null || state.answer.answer === null) && !state.answerComplete && !state.isFetching) {
        // console.log(state.isFetching);
        // console.log(JSON.stringify(state));
        let cid = getCidFromUrl();
        return (
            <div className="padding-3">
                <h3>Leider konnten wir Ihren Link nicht finden :-(</h3>
                <p>Wenn Sie das für einen Fehler halten, dann wenden Sie sich bitte an unseren Kundenservice:</p>
                { (cid !== CID_RHION && cid !== CID_RHEINLAND) &&  
                <p><a href={"tel:" + props.appState?.customizing?.serviceTel}>{props.appState?.customizing?.serviceTel}</a></p>
                }
                { (cid === CID_RHION) && 
                <p>E-Mail: <a href={"mailto:" + props.appState?.customizing?.serviceEmail}>{props.appState?.customizing?.serviceEmail}</a></p>
                }
                { (cid === CID_RHEINLAND) && 
                <p>E-Mail: <a href={"mailto:" + props.appState?.customizing?.serviceEmail}>{props.appState?.customizing?.serviceEmail}</a></p>
                }
            </div>
        );
    }
    if (state.link !== null && state.redirect !== null && state.link.redirectURL !== undefined && RedirectType[RedirectType.IMMEDIATE] === String(state.redirect)) {
        let redirect: string = state.link.redirectURL;
        return (
            <Route path='/' component={() => {
                window.location.href = redirect;
                return (
                    <div className="padding-3">
                        <h3>Sie werden weitergeleitet...</h3>
                    </div>
                );
            }} />
        );
    }
    // Link wurde beantwortet
    if (state.answerComplete) {
        /** Falls eine externe URL reingereicht wurde, zu dieser weiterleiten. */
        if (state.link && state?.link?.redirectURL !== undefined && state?.link?.redirectURL !== null) {
            let redirect: string = state.link.redirectURL;
            return (
                <Route path='/' component={() => {
                    window.location.href = redirect;
                    return (
                        <div className="padding-3">
                            <h3>Sie werden weitergeleitet...</h3>
                        </div>
                    );
                }} />
            );
        } else {
            /** Ansonsten die normale Erfolgsseite anzeigen. */
            let isWizard: boolean = state.wizardState.wizardSteps !== undefined && state.wizardState.isWizard === true && state.answer !== undefined && state.answer !== null;
            return <>
                {isWizard && <Wizard appState={props.appState} confirmationPage={getSuccessElement(false)}  submitFormProgrammatically={submitFormProgrammatically}/>}
                {!isWizard && <div>{getSuccessElement(true)}</div>}
            </>;
        }
    }
    /** Ladeanzeige. */
    if (state.isFetching) {
        return (
            <div className="padding-3">
                <h3>Sekunde, wir laden Ihre LinkedApp...</h3>
            </div>
        );
    }
    /** Ladeanzeige. */
    else if (state.isSaving) {
        return (
            <div className="padding-3">
                <h3>Moment, wir legen Ihre Daten auf unserem Server ab...</h3>
            </div>
        )
        // Formular anzeigen
    } else if (state.answer && state.answer.answer && state.answer.answer.answerElement && state.answer.answer.answerElement.value) {
        let clean: string = (state.answer && state.answer.answer && state.answer.answer.informationtext && DOMPurify.sanitize(state.answer.answer.informationtext, { ADD_ATTR: ['target'] })) || '';
        let contactblock = getContactBlock();
        let message = getMessage();
        let button = state.wizardState?.isWizard === false && getSaveButton();
        let isWizard: boolean = state.wizardState.wizardSteps !== undefined && state.wizardState.isWizard === true && state.answer !== undefined && state.answer !== null;
        return (

            <Box sx={{
                "& a": {
                    color: 'primary.main' || '#0069b4',
                    textDecoration: 'underline',
                    transition: '0.1s ease-in',
                },
                "& a:hover": {
                    opacity: 0.8,
                    transition: "0.1s ease-in",
                },
            }} className="answer-container">
                {state.linkId !== null && state.link?.historyID !== null && state.linkId !== state.link?.historyID && state.isHiddePostfachLink === false &&
                    <Box sx={{ paddingBottom: '20px' }}>
                        <Button onClick={toCustomerHistory} startIcon={<FolderSharedIcon sx={{ verticalAlign: 'bottom', marginRight: '8px' }} />}>Zum Postfach </Button>
                    </Box>
                }
                {errormessage}
                {message}
                {contactblock}
                {clean !== '' && <Box dangerouslySetInnerHTML={{ __html: clean }} sx={{
                    "& a": {
                        color: 'primary.main' || '#0069b4',
                        textDecoration: 'underline',
                        transition: '0.1s ease-in',
                    },
                    "& a:hover": {
                        opacity: 0.8,
                        transition: "0.1s ease-in",
                    },
                    padding: '1rem',
                    marginBottom: '1rem',
                    borderRadius: '0 1rem 1rem 1rem',
                    background: '#ffffff',
                    backgroundColor: 'info.light' || '#ffffff',
                }}></Box>}
                <form ref={formRef} id="answerform" onSubmit={onSubmit} encType="multipart/form-data" >
                    {isWizard && <Wizard appState={props.appState} getSaveButton={getSaveButton} setPathToPage={setPathToPage} submitFormProgrammatically={submitFormProgrammatically}/>}
                    {!isWizard && <>
                        {state?.answer?.answer.answerElement.value.map((el: AnswerElement) => (
                            <HighlightFrame appState={props.appState} elementid={el.element.externalid} key={el.element.externalid}>
                                <AnswerElementGui appState={props.appState} key={el.element.externalid} el={el} submitFormProgrammatically={submitFormProgrammatically}/>
                            </HighlightFrame>
                        ))}
                    </>}
                    <ValidationMessage container spacing={3} />
                    <Grid container spacing={3} className="row buttonrow">
                        <Grid item xs={12}>
                            {button}
                        </Grid>
                    </Grid>
                </form>

                <ShareDialog saveLinkTemporary={saveLinkTemporary} />
            </Box>
        );
    } else {
        return (
            <></>
        );
    }
}

export default Answer